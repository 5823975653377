@font-face {
	font-family: "AvenirNext-Bold";
	src: url("./assets/fonts/AvenirNext-Bold.woff") format("woff");
	src: url("./assets/fonts/AvenirNext-Bold.woff2") format("woff2");
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: "AvenirNext-DemiBold";
	src: url("./assets/fonts/AvenirNext-DemiBold.woff") format("woff");
	src: url("./assets/fonts/AvenirNext-DemiBold.woff2") format("woff2");
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: "AvenirNext-Heavy";
	src: url("./assets/fonts/AvenirNext-Heavy.ttf") format("TrueType");
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: "AvenirNext-Medium";
	src: url("./assets/fonts/AvenirNext-Medium.woff") format("woff");
	src: url("./assets/fonts/AvenirNext-Medium.woff2") format("woff2");
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: "AvenirNext-Regular";
	src: url("./assets/fonts/AvenirNext-Regular.woff") format("woff");
	src: url("./assets/fonts/AvenirNext-Regular.woff2") format("woff2");
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: "Lato";
	src: url("./assets/fonts/Lato-regular.woff") format("woff");
	src: url("./assets/fonts/Lato-regular.woff2") format("woff2");
	text-rendering: optimizeLegibility;
}

* {
	font-family: "AvenirNext-Regular";
}

html,
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	width: 100%;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-form-item-explain-error {
	margin-top: 4px;
	font-family: AvenirNext-Medium;
	font-size: 13px;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
	border-color: #0e9ef3;
	background: #0e9ef3;
}

.ant-input::placeholder {
	color: #8e929a;
	font-family: AvenirNext-Medium;
	font-size: 13px;
	line-height: 17px;
	margin-top: 2px;
}
